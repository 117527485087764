import React from 'react'
import Axios from 'axios'
import moment from 'moment-timezone'
import { format } from 'date-fns'
import { LineChart, ResponsiveContainer, Line, XAxis, CartesianGrid, YAxis, Tooltip, ReferenceLine } from 'recharts'
import useSWR from 'swr'
import { weatherIconFromCode } from '../lib/weather'
import { WeatherChartTickIcon } from './WeatherChartTickIcon'


async function fetchWeather() {
  const url = `/api/weather`
  const res = await Axios.get(url)
  return res.data
}

function capitalize(s: string) {
  return s.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
}

function tickFormatter(dt: number) {
  return moment.unix(dt).format("MM-DD HH:mm")
}

export default function Weather() {
  const { data: weatherData, error } = useSWR<Weather.Data | null>("weather", fetchWeather)
  const isLoading = !weatherData && !error
  const degUnit = <>&deg;C</>
  if (isLoading) return <>Loading weather...</>

  const asOf = weatherData ? moment.unix(weatherData.current.dt).format("YYYY-MM-DD HH:mm") : "..."
  const weatherDescription = capitalize(weatherData?.current.weather[0].description ?? "")

  return <div>
    <div className="text-lg">
      {weatherData?.current.name}
      {' '}
      <span className="text-zinc-500 text-xs">
        [{weatherData?.current.coord.lon}, {weatherData?.current.coord.lat}]
      </span>
    </div>

    <div className="text-sm text-zinc-800">as of {asOf}</div>

    <div className="flex items-center">
      <div>
        <span
          style={{ fontSize: "3rem", margin: "1rem" }}
          className={"bi bi-" + weatherIconFromCode(weatherData?.current.weather[0].icon || "").icon || ""} />
      </div>

      <div className="grow">
        <div className="text-2xl">
          {weatherData?.current.main.temp}{degUnit}
        </div>
        <div className="text-lg">
          {' '}{weatherDescription}
        </div>
      </div>
    </div>


    <div className="text-xl">
      <span className="text-sm text-slate-500">
        Feels like{' '}</span>
      {weatherData?.current.main.feels_like}{degUnit}
    </div>

    <div className="text-xl">
      <span className="text-sm text-slate-500">
        Low{' '}
      </span>
      {weatherData?.current.main.temp_min}{degUnit}
      {' '}
      <span className="text-sm text-slate-500">
        High{' '}
      </span>
      {weatherData?.current.main.temp_max}{degUnit}
    </div>

    <div className="text-xl">
      {weatherData?.current.wind.speed} km/h
      <span className="text-sm text-slate-500">
        {' '}wind</span>
    </div>

    <div className="text-xl">
      {weatherData?.current.main.humidity}{'%'}
      <span className="text-sm text-slate-500">
        {' '}humidity
      </span>
      {' '}
      {weatherData?.current.clouds.all}{'%'}
      <span className="text-sm text-slate-500">
        {' '}clouds
      </span>
    </div>
    <div className="text-slate-800 text-center">Five Day</div>
    <div style={{ height: 200, width: "100%", position: "relative" }}>
      <div style={{ position: "absolute", width: "100%", height: "100%" }}>
        <ResponsiveContainer>
          <LineChart data={weatherData?.fiveDay.list}>
            <CartesianGrid
              vertical={false}
              strokeDasharray="3 5"
              stroke="#aaa"
            />
            <ReferenceLine
              xAxisId="dt"
              yAxisId="temperature"
              y={0}
              stroke="#888"
            />
            {weatherData?.fiveDay.list
              .filter(v => format(v.dt * 1000, "HH") === "01")
              .map(v => <ReferenceLine
                xAxisId="dt"
                yAxisId="temperature"
                stroke="#aaa"
                strokeDasharray="3 5"
                key={v.dt} x={v.dt} />)}
            <Line
              dataKey="main.temp"
              xAxisId="dt"
              yAxisId="temperature"
              name="Weather"
              dot={false}
              strokeWidth={0}
            />
            <Line dataKey="main.temp"
              name="Temperature"
              xAxisId="dt"
              yAxisId="temperature"
              dot={{ r: 0.6, fill: "red" }}
              stroke="#f35"
            />
            <Line dataKey="main.humidity"
              name="Humidity"
              xAxisId="dt"
              yAxisId="humidity"
              dot={{ r: 0.6, fill: "blue" }}
              stroke="#58f"
            />
            <XAxis
              xAxisId="dt"
              dataKey="dt"
              tickFormatter={tickFormatter} />
            <XAxis orientation="top"
              tick={<WeatherChartTickIcon data={weatherData?.fiveDay.list} />}
              xAxisId={"weather"}
              axisLine={false}
              minTickGap={3}
              tickLine={false} />
            <YAxis
              orientation="left"
              width={30}
              stroke="#f35"
              yAxisId="temperature" />
            <YAxis
              orientation="right"
              width={30}
              stroke="#58f"
              yAxisId="humidity" />
            <Tooltip
              labelFormatter={tickFormatter}
              allowEscapeViewBox={{ x: false, y: true }}
              isAnimationActive={false}
              formatter={(d: number, n: string, p: any) => {
                switch (n) {
                  case "Temperature":
                    return <>{d}{degUnit}</>
                  case "Weather":
                    return <><span style={{ fontSize: "2rem", }} className={"bi bi-" + weatherIconFromCode(p.payload.weather[0].icon || "")?.icon || ""}></span> {capitalize(p.payload.weather[0].description)}{p.payload.rain && ', 3h: ' + p.payload.rain['3h'] + "mm"}</>
                  case "Humidity":
                    return <>{d}%</>
                  default:
                    return <>{d}</>
                }
              }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  </div>
}