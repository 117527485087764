export default function Mission() {
  return <div>
    <h2 className="text-3xl">Mission</h2>
    <ol className="list-decimal ml-5">
      <li>
        <span>Family</span>
        <ol className="list-decimal ml-10">
          <li>Support the family</li>
          <li>Buy an aparment</li>
          <li>Get married</li>
          <li>Buy a townhouse</li>
          <li>Buy a house</li>
        </ol>
      </li>
      <li>
        <span>Financial Security</span>
        <ol className="list-decimal ml-10">
          <li>Learn continuously</li>
          <li>Invest regularly</li>
        </ol>
      </li>
      <li>
        <span>Business</span>
        <ol className="list-decimal ml-10">
          <li>POS app</li>
          <li>Print &amp; sign shop</li>
          <li>Other business ideas when finances established</li>
        </ol>
      </li>
      <li>
        <span>Personal Health</span>
        <ol className="list-decimal ml-10">
          <li>Stretch daily</li>
          <li>Exercise twice weekly</li>
          <li>Drink water</li>
          <li>Read Regularly</li>
        </ol>
      </li>
    </ol>
  </div>

}