import { useEffect } from "react"
import { verifyLogin } from "../store/actions/login"
import { useAppDispatch } from "../store/store"

export function Init() {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(verifyLogin())
  })
  return null
}