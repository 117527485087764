export function Philosophy() {
  return <div>
    <h2 className="text-3xl">Philosophy</h2>
    <ol className="list-decimal ml-5">
      <li>Finance</li>
      <li>Continuous Improvement</li>
      <li>Innovation
        <ul className="list-disc ml-10">
          <li>Widen the search, broaden the scope</li>
          <li>Tighten human connections between innovators and existing people in business</li>
          <li>Add flexibility to planning and control systems</li>
          <li>Select leaders with strong interpersonal skills</li>
          <li>Source: Innovation, Rosabeth Moss Kanter, HBR 10 Must Reads - The Essentials, 2011</li>
        </ul>
      </li>
      <li>Leadership
        <ol className="list-decimal ml-10">
          <li>Integrity</li>
          <li>Communication</li>
        </ol>
      </li>
      <li>Leave a positive impact in the world</li>
    </ol>
  </div>
}