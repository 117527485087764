import React, { useEffect, useState } from "react"
import { useManualState, useStatus } from "../lib/aqLights"
import validator from "validator"
import AqLightConfig from "./AqLightConfig"
import { AnimatePresence, motion } from "framer-motion"

interface AqLightControlsProps {
  name: AqLights.AqName,
  channel: number
}
export default function AqLightControls({ name, channel }: AqLightControlsProps) {
  const [customValue, setCustomValue] = useState("")
  const [customValueError, setCustomValueError] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [manualState, setManualState] = useManualState(name, channel)
  const status = useStatus(name, channel)

  useEffect(() => {
    if (typeof manualState === "number") setCustomValue(manualState.toString())
    else {
      setCustomValue("")
      setCustomValueError(false)
    }
  }, [manualState])

  const handleAutoClick = () => {
    setManualState("auto")
  }

  const handleOnClick = () => {
    setManualState("on")
  }

  const handleOffClick = () => {
    setManualState("off")
  }

  const handleSetClick = () => {
    setManualState(parseFloat(customValue))
  }

  const handleCustomKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSetClick()
    }
  }

  const handleCustomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    let isError = false
    if (value === "") isError = false
    else if (!validator.isFloat(value)) { isError = true }
    else {
      const n = parseFloat(value)
      if (n < 0 || n > 100) isError = true
    }
    setCustomValueError(isError)
    setCustomValue(value)
  }

  return <div className="p-2 border rounded shadow">
    <div className="grid grid-cols-12 gap-2">
      <div className="col-span-12">
        <div className="text-sm text-slate-500 text-center">
          Controller {name} &middot; Channel {channel} &middot; TP: {status?.targetPercent ?? -1}
        </div>
      </div>
      <div className="col-span-4">
        <button
          className={`btn-primary w-full ${manualState === "auto" ? "active" : ""}`}
          onClick={handleAutoClick}
        >Auto</button>
      </div>
      <div className="col-span-4">
        <button
          className={`btn-primary w-full ${manualState === "on" ? "active" : ""}`}
          onClick={handleOnClick}
        >On</button>
      </div>
      <div className="col-span-4">
        <button
          className={`btn-primary w-full ${manualState === "off" ? "active" : ""}`}
          onClick={handleOffClick}
        >Off</button>
      </div>
      <div className="col-span-12">
        <div>
          <label className="text-sm text-zinc-500">Custom</label>
        </div>
        <div className="flex gap-1 items-center">
          <div className="grow">
            <input
              type="number"
              className={`text-field w-full ${customValueError ? "error" : ""}`}
              onKeyUp={handleCustomKeyUp}
              value={customValue}
              onChange={handleCustomChange}
            />
          </div>
          {typeof manualState === "number" &&
            <div>
              <div className="text-2xl">
                {manualState.toString() === customValue
                  ? <span className="bi bi-check-circle-fill text-sky-700" />
                  : <span className="bi bi-dash-circle" />
                }
              </div>
            </div>
          }
          <div>
            <button
              disabled={customValue === "" || customValueError}
              className="btn-primary"
              onClick={handleSetClick}>Set</button>
          </div>
        </div>
      </div>
      <div className="col-span-12">
        <div className="border rounded overflow-hidden">
          <button className="btn-accordion" onClick={() => setExpanded(expanded => !expanded)}>
            <div className="flex-grow text-left">Config</div>
            {expanded
              ? <span className="bi bi-chevron-down" />
              : <span className="bi bi-chevron-up" />
            }
          </button>
          <AnimatePresence initial={false}>
            {expanded &&
              <motion.section
                className="overflow-hidden"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0, }
                }}
              >
                <div className="p-2">
                  <AqLightConfig name={name} channel={channel} />
                </div>
              </motion.section>
            }
          </AnimatePresence>
        </div>
      </div>
    </div>
  </div>
}