import PageHeader from './PageHeader'
import LoginForm from './LoginForm'
import { Init } from './Init'
import { MainRoutes } from './MainRoutes'

export default function Main() {
  return <div>
    <Init />
    <LoginForm />
    <PageHeader />
    <div className="container mx-auto">
      <MainRoutes />
    </div>
  </div>
}