import Clock from "../Clock"
import CompoundInterest from "../CompoundInterest"
import DaysSince from "../DaysSince"
import { Covid } from "../Covid"
import Mission from "../Mission"
import News from "../News"
import Weather from "../Weather"
import { Philosophy } from "../Philosophy"
import { QuoteBox } from "../QuoteBox"
import StyledPaper from "../StyledPaper"
import Greeting from "../Greeting"
import { useAppSelector } from "../../store/store"

export function Home() {
  const isLoggedIn = useAppSelector(state => state.login.isLoggedIn)
  return <div className="grid grid-cols-12 gap-2">
    <div className="col-span-12">
      <StyledPaper>
        <Greeting />
      </StyledPaper>
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4">
      <div className="grid gap-2">
        <div>
          <StyledPaper>
            <Clock />
          </StyledPaper>
        </div>
        <div>
          <StyledPaper>
            <Weather />
          </StyledPaper>
        </div>
        {isLoggedIn &&
          <div>
            <StyledPaper>
              <CompoundInterest />
            </StyledPaper>
          </div>
        }
      </div>
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4">
      <div className="grid gap-2">
        <div>
          <StyledPaper>
            <QuoteBox />
          </StyledPaper>
        </div>
        <div>
          <StyledPaper>
            <Covid />
          </StyledPaper>
        </div>
        {isLoggedIn &&
          <div>
            <StyledPaper>
              <DaysSince />
            </StyledPaper>
          </div>
        }
        {isLoggedIn &&
          <div>
            <StyledPaper>
              <Mission />
            </StyledPaper>
          </div>
        }
        {isLoggedIn &&
          <div>
            <StyledPaper>
              <Philosophy />
            </StyledPaper>
          </div>
        }
      </div>
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4">
      <div className="grid gap-2">
        <StyledPaper>
          <div className="max-h-[600px] overflow-y-auto">
            <News />
          </div>
        </StyledPaper>

      </div>
    </div>
  </div>
}