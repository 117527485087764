import axios from "axios"
import useSWR from "swr"


async function apiGet(url: string) {
  const res = await axios.get(url)
  return res.data
}

export async function apiPost<T = any>(url: string, data: T) {
  const res = await axios.post(url, data)
  return res.data
}


export function useApiGet<T = any>(url: string | null) {
  const { data, error } = useSWR<T>(url, apiGet)
  // console.log(data)
  return { data, error }
}
