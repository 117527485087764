import React, { useMemo } from 'react'
import moment from 'moment-timezone'

export default function Greeting() {
  const { timeOfDay, message } = useMemo(() => {
    const hour = moment().hour()
    if (hour < 12) {
      return {
        timeOfDay: "morning",
        message: "Have an amazing day!",
      }
    }
    else if (hour < 17) {
      return {
        timeOfDay: "afternoon",
        message: "Keep up the awesome!",
      }
    }
    return {
      timeOfDay: "evening",
      message: "Hope you had a great day! Tomorrow's going to be even better!",
    }
  }, [])
  return <div>
    <div className="text-3xl text-center">
      Good {timeOfDay}, Oceanne &amp; Boris.
    </div>
    <div className="text-xl text-center">
      {message}
    </div>
  </div>
}