import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment-timezone'

export default function Clock() {
  var [time, setTime] = useState<moment.Moment | null>(null)
  var wrapperRef = useRef<HTMLDivElement | null>(null)
  var canvasRef = useRef<HTMLCanvasElement | null>(null)
  useEffect(() => {
  }, [])
  useEffect(() => {
    var innerTime: moment.Moment
    type TimeType = "second" | "minute" | "hour"
    var drawHand = (ctx: CanvasRenderingContext2D, width: number, height: number, value: number, type: TimeType) => {
      var r = (width < height ? width : height) / 2
      var lineWidth = 1
      var maxValue = 60
      var strokeStyle = "#000"
      switch (type) {
        case 'second':
          r = r * 0.9
          lineWidth = 2
          strokeStyle = "#558"
          break
        case 'minute':
          r = r * 0.7
          lineWidth = 3.5
          strokeStyle = "#505"
          break
        case 'hour':
          r = r * 0.3
          while (value > 12) value -= 12
          strokeStyle = "#500"
          lineWidth = 7
          maxValue = 12
          break
        default:
          throw new Error("Wrong value passed in")
      }
      var rad = (2 * Math.PI) * (value / maxValue) - (0.5 * Math.PI)
      var x = Math.cos(rad) * r
      var y = Math.sin(rad) * r
      ctx.lineWidth = lineWidth
      ctx.lineCap = "round"
      ctx.strokeStyle = strokeStyle
      ctx.beginPath()
      ctx.moveTo(width / 2, height / 2)
      ctx.lineTo(x + (width / 2), y + (height / 2))
      ctx.stroke()
    }
    var drawFace = (ctx: CanvasRenderingContext2D, width: number, height: number) => {
      var offsetX = width / 2
      var offsetY = height / 2
      var r = (offsetX < offsetY ? offsetX : offsetY) - 2
      ctx.lineWidth = 1.5
      ctx.beginPath()
      ctx.arc(offsetX, offsetY, r, 0, 2 * Math.PI)
      ctx.stroke()
      ctx.lineWidth = 1.2
      ctx.beginPath()
      ctx.arc(offsetX, offsetY, r - 25, 0, 2 * Math.PI)
      ctx.stroke()
      ctx.strokeStyle = "rgb(55,55,55)"
      var rad
      var tickX1
      var tickY1
      var tickX2
      var tickY2
      for (var i = 0; i < 60; i++) {
        rad = (2 * Math.PI) * (i / 60) - (0.5 * Math.PI)
        tickX1 = Math.cos(rad) * (r - 35)
        tickY1 = Math.sin(rad) * (r - 35)
        tickX2 = Math.cos(rad) * (r - 25)
        tickY2 = Math.sin(rad) * (r - 25)
        ctx.lineWidth = 1.1
        ctx.beginPath()
        ctx.moveTo(offsetX + tickX1, offsetY + tickY1)
        ctx.lineTo(offsetX + tickX2, offsetY + tickY2)
        ctx.stroke()
      }
      for (var j = 0; j < 12; j++) {
        rad = (2 * Math.PI) * (j / 12) - (0.5 * Math.PI)
        tickX1 = Math.cos(rad) * (r - 38)
        tickY1 = Math.sin(rad) * (r - 38)
        tickX2 = Math.cos(rad) * (r - 25)
        tickY2 = Math.sin(rad) * (r - 25)
        ctx.lineWidth = 2.8
        ctx.beginPath()
        ctx.moveTo(offsetX + tickX1, offsetY + tickY1)
        ctx.lineTo(offsetX + tickX2, offsetY + tickY2)
        ctx.stroke()
      }
      [3, 6, 9, 12].forEach(i => {
        var rad = (2 * Math.PI) * (i / 12) - (0.5 * Math.PI)
        var textX = Math.cos(rad) * (r - 13)
        var textY = Math.sin(rad) * (r - 13)
        ctx.textAlign = "center"
        ctx.textBaseline = "middle"
        ctx.font = "22px sans-serif"
        ctx.fillText(String(i), offsetX + textX, offsetY + textY)
      })
    }
    var drawCenter = (ctx: CanvasRenderingContext2D, width: number, height: number) => {
      var offsetX = width / 2
      var offsetY = height / 2
      ctx.fillStyle = "#335"
      ctx.beginPath()
      ctx.arc(offsetX, offsetY, 7, 0, 2 * Math.PI)
      ctx.fill()
      ctx.fillStyle = "#fff"
      ctx.save()
      ctx.beginPath()
      ctx.arc(offsetX, offsetY, 3, 0, 2 * Math.PI)
      ctx.clip()
      ctx.clearRect(0, 0, width, height)
      ctx.restore()
    }
    var draw = () => {
      var canvas = canvasRef.current
      if (!canvas) return
      var width = 250
      var height = 250
      var dpr = window.devicePixelRatio
      canvas.width = width * dpr
      canvas.height = height * dpr
      var ctx = canvas.getContext('2d')
      if (!ctx) return
      ctx.scale(dpr, dpr)
      ctx.clearRect(0, 0, width, height)
      drawFace(ctx, width, height)
      drawHand(ctx, width, height, innerTime.hour() + innerTime.minute() / 60, 'hour')
      drawHand(ctx, width, height, innerTime.minute(), 'minute')
      drawHand(ctx, width, height, innerTime.second(), 'second')
      drawCenter(ctx, width, height)
    }
    var animate = () => {
      requestAnimationFrame(draw)
      innerTime = moment().tz('America/Vancouver')
      setTime(innerTime)
    }
    animate()
    var interval = setInterval(animate, 200)
    var handleWindowResize = () => {
      animate()
    }
    window.addEventListener('resize', handleWindowResize)
    handleWindowResize()
    return () => {
      window.removeEventListener('resize', handleWindowResize)
      clearInterval(interval)
    }
  }, [])
  return (
    <div className="Clock flex flex-col items-center text-center">
      <div className="DateTime">
        <div className="DayOfWeek">
          {time && time.format("dddd")}
        </div>
        <div className="Date">
          {time && time.format("YYYY-MM-DD")}
        </div>
      </div>
      <div className="Clock-CanvasWrapper"
        ref={wrapperRef}>
        <canvas
          className="Clock-Canvas"
          style={{ width: "100%", maxWidth: "250px" }}
          ref={canvasRef}
        />
      </div>
      <div className="DateTime">
        <div className="Time">
          {time && time.format("HH:mm:ss")}
        </div>
      </div>
    </div>
  )
}