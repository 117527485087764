import React, { useMemo, useState, useCallback } from 'react'
import moment from 'moment-timezone'
import produce from 'immer'
import validator from 'validator'
import { XAxis, YAxis, ResponsiveContainer, Line, CartesianGrid, Tooltip, Area, ComposedChart, ReferenceLine } from 'recharts'


type InputKey = "interestRate" | "nCompoundPerYear" | "contributionPerN"

type Field = {
  key: InputKey,
  label: String,
  validator: (value: string) => boolean,
}

const dateFormat = "YYYY-MM"

const fields: Field[] = [
  { key: "interestRate", label: "Interest Rate", validator: validator.isFloat, },
  { key: "nCompoundPerYear", label: "n Compound per Year", validator: validator.isFloat },
  { key: "contributionPerN", label: "Contribution per n", validator: validator.isFloat },
]

function compoundAmount(P: number, r: number, n: number, t: number) {
  return P * Math.pow((1 + (r / n)), (n * t))
}

function compountAmountWithContrib(P: number, r: number, n: number, t: number, pmt: number) {
  return compoundAmount(P, r, n, t)
    + (pmt * ((Math.pow(1 + (r / n), n * t) - 1) / (r / n)))
  // * (1 + (r / n)))
}

function interestEarnedWithContrib(P: number, r: number, n: number, t: number, pmt: number) {
  return compountAmountWithContrib(P, r, n, t, pmt) - contribution(P, n, t, pmt)
}

function contribution(P: number, n: number, t: number, pmt: number) {
  return (P + (pmt * t * n))
}

function currencyString(x: number) {
  return x.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  // return (Math.round(x * 100) / 100).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export default function CompoundInterest() {
  // A = P((1 + (r / n)) ^ (n * t))
  // I = ((P * (1 + i) ^ n) - P)
  const startAmount = 75000
  const startDate = moment("2020-07-18")
  const endDate = startDate.clone().add(30, "years")

  const [inputStrings, setInputStrings] = useState<Record<InputKey, string>>({
    interestRate: "0.07",
    nCompoundPerYear: "12",
    contributionPerN: "2500",
  })

  const updateInputString = useCallback((key: InputKey, value: string) => {
    setInputStrings(produce((draft) => {
      draft[key] = value
    }))
  }, [])

  const [inputData, setInputData] = useState<Record<InputKey, number>>({
    interestRate: 0.07,
    nCompoundPerYear: 12,
    contributionPerN: 2500,
  })

  const handleSetClick = useCallback(() => {
    for (let field of fields) {
      if (!field.validator(inputStrings[field.key])) return
    }

    setInputData(produce((draft) => {
      for (let field of fields) {
        draft[field.key] = parseFloat(inputStrings[field.key])
      }
    }))
  }, [inputStrings])

  const data = useMemo(() => {
    const data = []
    for (let d: moment.Moment = startDate.clone(); d.isSameOrBefore(endDate); d.add(1, 'month')) {
      const t = d.diff(startDate, 'years', true)
      data.push({
        date: d.format(dateFormat),
        contribution: contribution(startAmount, inputData.nCompoundPerYear, t, inputData.contributionPerN),
        interestEarned: interestEarnedWithContrib(startAmount, inputData.interestRate, inputData.nCompoundPerYear, t, inputData.contributionPerN),
        total: compountAmountWithContrib(startAmount, inputData.interestRate, inputData.nCompoundPerYear, t, inputData.contributionPerN),
      })
    }
    return data
  }, [endDate, inputData.contributionPerN, inputData.interestRate, inputData.nCompoundPerYear, startDate])

  const thisMonth = moment().format(dateFormat)

  const thisMonthTarget = useMemo(() => {
    return data.find(row => row.date === thisMonth)
  }, [data, thisMonth])

  return <div>
    <div style={{ height: 300, width: "100%", position: "relative", zIndex: 9999 }}>
      <div style={{ position: "absolute", width: "100%", height: "100%" }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={data} margin={{ left: 50 }}>
            <XAxis dataKey="date" />
            <YAxis
              tickFormatter={value => currencyString(value)}
            />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Tooltip
              isAnimationActive={false}
              allowEscapeViewBox={{ x: false, y: true }}
              formatter={(value: number) => currencyString(value)}
            />
            <Area
              type="monotone"
              dataKey="contribution"
              stackId="1"
              stroke="#8884d8"
              fill="#8884d8" />
            <Area
              type="monotone"
              dataKey="interestEarned"
              stackId="1"
              stroke="#82ca9d"
              fill="#82ca9d"
            />
            <Line
              type="monotone"
              dataKey="total"
              dot={false}
              stroke="#8884d8"
            />
            <ReferenceLine stroke="#a00" x={thisMonth} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
    <div className="grid grid-cols-12 gap-2">
      <div className="col-span-12">
        {`${thisMonth} Target: ${currencyString(thisMonthTarget?.total || 0)}`}
      </div>
      {fields.map(field =>
        <div key={field.key} className="col-span-12 lg:col-span-4">
          <label>{field.label}</label>
          <input
            className={`text-field w-full ${!field.validator(inputStrings[field.key]) ? "error" : ""}`}
            value={inputStrings[field.key]}
            onChange={e => updateInputString(field.key, e.target.value)}
          />
        </div>
      )}
      <div className="col-span-12">
        <button
          className={"btn-primary w-full"}
          onClick={handleSetClick}>
          Set
        </button>
      </div>
    </div>
  </div>
}