import React from 'react'
import useSWR from 'swr'
import type RssParser from 'rss-parser'
import moment from 'moment-timezone'
import { fetchNews, hasSource } from '../lib/news'


export default function News() {
  const { data: newsData, error } = useSWR<RssParser.Item[] | null>("news", fetchNews)
  const isLoading = !newsData && !error
  if (isLoading) return <>Loading news...</>
  return <div className="grid gap-2">
    {newsData?.map(newsItem =>
      <div key={newsItem.guid}>
        <div className="bg-white border border-slate-300 rounded shadow p-3">
          <div className="flex flex-wrap">
            <div className="grow">
              <span className="text-xs text-zinc-500">
                {moment(newsItem.isoDate).tz('america/vancouver').format('YYYY-MM-DD HH:mm')}
              </span>
              <span className="text-xs text-zinc-500">
                {' ∙ '}{moment(newsItem.isoDate).tz('america/vancouver').fromNow()}
              </span>
            </div>
            <div>
              <span className="text-xs text-zinc-500">
                {hasSource(newsItem) && newsItem.source}
              </span>
            </div>
          </div>
          <a href={newsItem.link}
            target="_blank" rel="noopener noreferrer">
            <span>
              {newsItem.title}
            </span>
            {/* <Typography variant="body2" color="textSecondary">
                  {newsItem.contentSnippet}
                </Typography> */}
          </a>
        </div>
      </div>
    )}
  </div>
}