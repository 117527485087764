import React from 'react'
import moment from 'moment-timezone'


function displayDuration(duration: moment.Duration) {
  return `${duration.years()} years, ${duration.months()} months, ${duration.days()} days`
}

export default function DaysSince() {
  const dates = [
    { label: "Mom Born", date: moment("1956-09-23") },
    { label: "Boris Born", date: moment("1987-05-18") },
    { label: "Oceanne Born", date: moment("1996-05-05") },
    { label: "Hamlet Born", date: moment("2016-08-22") },
    { label: "Alive First Day", date: moment("2018-01-23") },
    { label: "Date Anniversary", date: moment("2018-07-27") },
    { label: "Beep Development Began", date: moment("2019-11-25") },
    { label: "Beep Retail Technologies Inc.", date: moment("2020-09-04") },
    { label: "Manushka Born", date: moment("2022-12-02") },
    // { label: "Billionaire", date: moment("2050-01-01") },
  ]

  const now = moment()
  return <div className="grid divide-y divide-slate-300">
    {dates.map(date => {
      const duration = moment.duration(now.diff(date.date))
      return <div key={date.label} className="p-1">
        <h3 className="font-semibold text-sm">{date.date.format("YYYY-MM-DD")} &middot; {date.label}</h3>
        <div className="text-sm">
          {`${displayDuration(duration)}.`}
          <span className="text-xs text-slate-500">
            {` ${Math.floor(duration.asDays())} days ago`}
          </span>
        </div>
      </div>
    })}
  </div>
}