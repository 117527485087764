import React, { useEffect, useMemo, useState } from "react"
import { useConfig } from "../lib/aqLights"
import validator from "validator"
import produce from "immer"
import { AqLightScheduleInput } from "./AqLightScheduleInput"


type InputValues = {
  schedule: AqLights.ScheduleItem[]
  fadeIntervalMs: string
  fadeSpeed: string
}
function configToInputValues(config: AqLights.Config | undefined): InputValues | null {
  if (!config) return null
  return {
    schedule: config.schedule,
    fadeIntervalMs: config.fadeIntervalMs.toString(),
    fadeSpeed: config.fadeSpeed.toString(),
  }
}

interface AqLightConfigProps {
  name: AqLights.AqName,
  channel: number
}
export default function AqLightConfig({ name, channel }: AqLightConfigProps) {
  const [config, setConfig] = useConfig(name, channel)
  const [inputValues, setInputValues] = useState<InputValues | null>(() => configToInputValues(config))

  interface ConfigDef {
    key: keyof AqLights.Config
    label: string
    type: "number" | "string" | "schedule"
  }
  const configDefs: ConfigDef[] = useMemo(() => [
    // { key: "minBrightness", label: "Minimum", type: "number" },
    // { key: "maxBrightness", label: "Maximum", type: "number" },
    // { key: "sunriseStartStr", label: "Sunrise Start (HH:MM:SS)", type: "string" },
    // { key: "sunsetStartStr", label: "Sunset Start Start (HH:MM:SS)", type: "string" },
    // { key: "sunriseDurationMs", label: "Sunrise Duration (ms)", type: "number" },
    // { key: "sunsetDurationMs", label: "Sunset Duration (ms)", type: "number" },
    { key: "schedule", label: "Schedule", type: "schedule" },
    { key: "fadeIntervalMs", label: "Fade Interval (ms)", type: "number" },
    { key: "fadeSpeed", label: "Fade Speed", type: "number" },
  ], [])

  useEffect(() => {
    if (!config) {
      setInputValues(null)
      return
    }
    setInputValues(configToInputValues(config))
  }, [config, configDefs])

  const handleSetClick = (e: React.MouseEvent<HTMLButtonElement>, def: ConfigDef) => {
    const value = inputValues?.[def.key]
    if (!value) return
    if (def.type === "number") {
      if (!validator.isFloat(value + "")) {
        alert("Invalid value")
        return
      }
      setConfig(def.key, parseFloat(value + ""))
    } else {
      setConfig(def.key, value)
    }
  }

  const setSchedule = (value: AqLights.ScheduleItem[]) => {
    setConfig("schedule", value)
  }

  const updateInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, def: ConfigDef) => {
    setInputValues(iv => produce(iv, draft => {
      if (!draft) return
      if (def.key === "schedule") return
      draft[def.key] = e.target.value
    }))
  }

  return <div className="grid gap-2 w-full">
    {configDefs.map(def =>
      <div key={def.key} className="">
        <div className="w-full">
          <label className="text-sm text-zinc-500">{def.label}</label>
        </div>
        {def.key === "schedule"
          ? <AqLightScheduleInput
            value={inputValues?.[def.key] ?? []}
            onChange={setSchedule}
          />
          : <div className="flex items-center">
            <div className="grow">
              <input
                className="text-field w-full"
                value={inputValues?.[def.key] ?? ""}
                onChange={e => updateInput(e, def)}
              />
            </div>
            <div>
              <button
                className="btn-primary"
                onClick={e => handleSetClick(e, def)}
              >Set</button>
            </div>
          </div>
        }
      </div>
    )}
  </div>

}