import StyledPaper from "../StyledPaper"
import { AqLights } from "../AqLights"
import { Controllers } from "../Controllers"

export function MCU() {
  return <div className="grid gap-2">
    <StyledPaper>
      <AqLights />
    </StyledPaper>
    <StyledPaper>
      <Controllers />
    </StyledPaper>
  </div>
}