import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../store/store"
import { setFormOpen } from "../store/reducers/login"
import { login } from "../store/actions/login"

export default function LoginForm() {
  const open = useAppSelector(state => state.login.formOpen)
  const dispatch = useAppDispatch()

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const handleCancel = () => {
    dispatch(setFormOpen(false))
  }

  const handleLogin = () => {
    async function doLogin() {
      if (await dispatch(login({ username, password })))
        dispatch(setFormOpen(false))
    }
    doLogin()
  }

  if (!open) return null
  return <div className="fixed left-0 top-0 bg-black/30 w-full h-full z-30">
    <div className="mx-auto my-5 md:my-12 bg-white rounded z-40 shadow-xl p-3 grid gap-2" style={{ maxWidth: 500 }}>
      <h2 className="text-2xl text-center">Login</h2>
      <div className="grid gap-2">
        <div className="grid">
          <label>Email Address</label>
          <input
            className="text-field"
            type="email"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </div>
        <div className="grid">
          <label>Password</label>
          <input
            className="text-field"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>
      </div>
      <div className="flex justify-end gap-2">
        <button className="btn-primary" onClick={handleCancel} >Cancel</button>
        <button className="btn-primary" onClick={handleLogin} >Login</button>
      </div>
    </div>
  </div>
}