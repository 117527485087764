import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { login, logout, verifyLogin } from "../actions/login"


type LoginReducerState = {
  formOpen: boolean
  isLoggedIn: boolean
  triedAutoLogin: boolean
  username: string
  nameFirst: string
  nameLast: string
}

const initialState: LoginReducerState = {
  formOpen: false,
  triedAutoLogin: false,
  isLoggedIn: false,
  username: "",
  nameFirst: "",
  nameLast: "",
}

const slice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setFormOpen: (state, action: PayloadAction<boolean>) => {
      state.formOpen = action.payload
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload
    },
    setTriedAutoLogin: (state, action: PayloadAction<boolean>) => {
      state.triedAutoLogin = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(verifyLogin.fulfilled, (state, action) => {
      state.username = action.payload.username
      state.nameFirst = action.payload.nameFirst
      state.nameLast = action.payload.nameLast
      state.isLoggedIn = true
      state.triedAutoLogin = true
    })
    builder.addCase(verifyLogin.rejected, (state, action) => {
      state.username = ""
      state.nameFirst = ""
      state.nameLast = ""
      state.isLoggedIn = false
      state.triedAutoLogin = true
    })
    builder.addCase(logout.fulfilled, (state) => {
      state.username = ""
      state.nameFirst = ""
      state.nameLast = ""
      state.isLoggedIn = false
    })
    builder.addCase(logout.rejected, (state) => {
      state.username = ""
      state.nameFirst = ""
      state.nameLast = ""
      state.isLoggedIn = false
    })
    builder.addCase(login.rejected, (state) => {
      state.username = ""
      state.nameFirst = ""
      state.nameLast = ""
      state.isLoggedIn = false
    })
  }
})

export const loginReducer = slice.reducer

export const {
  setFormOpen,
  setIsLoggedIn,
  setTriedAutoLogin,
} = slice.actions