
export type WeatherIcon = { name: string, icon: string, svgEncoded: string }

const weatherIconMap: Record<string, WeatherIcon> = {
  "01d": { name: "clear sky", icon: "sun", svgEncoded: "&#xf56e;" },
  "01n": { name: "clear sky", icon: "moon", svgEncoded: "&#xf56e;" },
  "02d": { name: "few clouds", icon: "cloud-sun", svgEncoded: "&#xf56e;" },
  "02n": { name: "few clouds", icon: "cloud-moon", svgEncoded: "&#xf56e;" },
  "03d": { name: "scattered clouds", icon: "cloud", svgEncoded: "&#xf56e;" },
  "03n": { name: "scattered clouds", icon: "cloud", svgEncoded: "&#xf56e;" },
  "04d": { name: "broken clouds", icon: "clouds", svgEncoded: "&#xf56e;" },
  "04n": { name: "broken clouds", icon: "clouds", svgEncoded: "&#xf56e;" },
  "09d": { name: "shower rain", icon: "cloud-rain-heavy", svgEncoded: "&#xf56e;" },
  "09n": { name: "shower rain", icon: "cloud-rain-heavy", svgEncoded: "&#xf56e;" },
  "10d": { name: "rain", icon: "cloud-rain", svgEncoded: "&#xf56e;" },
  "10n": { name: "rain", icon: "cloud-rain", svgEncoded: "&#xf56e;" },
  "11d": { name: "thunderstorm", icon: "cloud-lightning", svgEncoded: "&#xf56e;" },
  "11n": { name: "thunderstorm", icon: "cloud-lightning", svgEncoded: "&#xf56e;" },
  "13d": { name: "snow", icon: "snow3", svgEncoded: "&#xf56e;" },
  "13n": { name: "snow", icon: "snow2", svgEncoded: "&#xf56e;" },
  "50d": { name: "mist", icon: "cloud-haze2", svgEncoded: "&#xf56e;" },
  "50n": { name: "mist", icon: "cloud-haze2", svgEncoded: "&#xf56e;" },
}

export function weatherIconFromCode(code: string) {
  return weatherIconMap[code] || null
}