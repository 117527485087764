import React from "react"
import AqLightControls from "./AqLightControls"

export function AqLights() {
  return <div>
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2">
      <div>
        <div className="bg-white p-2 rounded shadow">
          <div className="text-lg font-bold text-center">10 Gallon</div>
          <div className="grid gap-2">
            <div>
              <AqLightControls name="aql-028562" channel={1} />
            </div>
            <div>
              <AqLightControls name="aql-028562" channel={2} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="bg-white p-2 rounded shadow">
          <div className="text-lg font-bold text-center">Growbox</div>
          <div className="grid gap-2">
            <div>
              <AqLightControls name="aql-135a24" channel={1} />
            </div>
            <div>
              <AqLightControls name="aql-135a24" channel={2} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="bg-white p-2 rounded shadow">
          <div className="text-lg font-bold text-center">4ch 1</div>
          <div className="grid gap-2">
            <div>
              <AqLightControls name="aql-104de1" channel={1} />
            </div>
            <div>
              <AqLightControls name="aql-104de1" channel={2} />
            </div>
            <div>
              <AqLightControls name="aql-104de1" channel={3} />
            </div>
            <div>
              <AqLightControls name="aql-104de1" channel={4} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}