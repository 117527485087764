import { useAppSelector } from "../../store/store"
import { ChangePasswordForm } from "../ChangePasswordForm"
import StyledPaper from "../StyledPaper"

export function Account() {
  const login = useAppSelector(state => state.login)
  return <div className="grid gap-2">
    <StyledPaper>
      <div className="grid gap-2">
        <div>
          Username: {login.username}
        </div>
        <div>
          First Name: {login.nameFirst}
        </div>
        <div>
          Last Name: {login.nameLast}
        </div>
      </div>
    </StyledPaper>
    <StyledPaper>
      <ChangePasswordForm />
    </StyledPaper>
  </div>
}