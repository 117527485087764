import axios from "axios"
import { useCallback } from "react"
import useSWR, { mutate } from "swr"

export async function postManualState(name: AqLights.AqName, channel: number, manualState: AqLights.ManualState) {
  const res = await axios.post(`/api/protected/aqlights/manualState/${name}/${channel}`, {
    manualState
  })
  return res.data
}

export async function fetchManualState(name: AqLights.AqName, channel: number) {
  const res = await axios.get(`/api/protected/aqlights/manualState/${name}/${channel}`)
  return res.data?.manualState
}

export function useManualState(name: AqLights.AqName, channel: number) {
  const { data: state, mutate: mutateManualState } = useSWR<AqLights.ManualState>([name, channel, "aqLightManualState"], key => fetchManualState(name, channel))
  const setManualStateValue = useCallback(async (value: AqLights.ManualState) => {
    await postManualState(name, channel, value)
    await mutateManualState()
    // Update aqLightStatus after updating manual state
    await mutate([name, channel, "aqLightStatus"])
  }, [channel, mutateManualState, name])
  return [state, setManualStateValue] as const
}

export async function fetchStatus(name: AqLights.AqName, channel: number) {
  const res = await axios.get(`/api/protected/aqlights/status/${name}/${channel}`)
  return res.data
}

export function useStatus(name: AqLights.AqName, channel: number) {
  const { data: status } = useSWR<AqLights.Status>([name, channel, "aqLightStatus"], key => fetchStatus(name, channel), { refreshInterval: 5000 })
  return status
}

async function fetchConfig(name: AqLights.AqName, channel: number) {
  const res = await axios.get(`/api/protected/aqlights/config/${name}/${channel}`)
  return res.data
}

async function postConfig(name: AqLights.AqName, channel: number, key: keyof AqLights.Config, value: any) {
  const res = await axios.post(`/api/protected/aqlights/config/${name}/${channel}/${key}`, { value })
  return res.data
}

export function useConfig(name: AqLights.AqName, channel: number) {
  const { data: config, mutate: mutateConfig } = useSWR<AqLights.Config>([name, channel, "aqLightConfig"], key => fetchConfig(name, channel))
  const setConfigValue = useCallback(async (key: keyof AqLights.Config, value: any) => {
    await postConfig(name, channel, key, value)
    await mutateConfig()
    // Update aqLightStatus after updating config
    await mutate([name, channel, "aqLightStatus"])
  }, [channel, mutateConfig, name])
  return [config, setConfigValue] as const
}