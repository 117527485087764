import { Provider } from 'react-redux'
import { store } from './store/store'
import Main from './components/Main'

import './App.css'
import { BrowserRouter } from 'react-router-dom'

export default function App() {
  return <div className="App">
    <BrowserRouter>
      <Provider store={store}>
        <Main />
      </Provider>
    </BrowserRouter>
  </div>
}