import React from "react"
import Axios from "axios"
import useSWR from "swr"


async function fetchCovid() {
  const url = `/api/covid`
  const res = await Axios.get(url)
  return res.data as Covid.Summary
}

export function Covid() {
  const { data: covidData, error } = useSWR<Covid.Summary | null>("covid", fetchCovid)
  const isLoading = !covidData && !error

  if (isLoading || !covidData) return <>Loading covid...</>

  return <div className="text-center">
    <div className="text-lg font-bold">
      COVID-19 Stats BC</div>
    <div>
      {covidData.bc.confirmed.toLocaleString()}{' Confirmed \xb7 '}
      {covidData.bc.deaths.toLocaleString()}{' Dead \xb7 '}
      {covidData.bc.recovered.toLocaleString()}{' Recovered '}
    </div>
    <div className="text-lg font-bold">
      COVID-19 Stats Canada</div>
    <div>
      {covidData.canada.confirmed.toLocaleString()}{' Confirmed \xb7 '}
      {covidData.canada.deaths.toLocaleString()}{' Dead \xb7 '}
      {covidData.canada.recovered.toLocaleString()}{' Recovered '}
    </div>
    <div className="text-lg font-bold">
      COVID-19 Stats World</div>
    <div>
      {covidData.world.confirmed.toLocaleString()}{' Confirmed \xb7 '}
      {covidData.world.deaths.toLocaleString()}{' Dead \xb7 '}
      {covidData.world.recovered.toLocaleString()}{' Recovered '}
    </div>
  </div>
}