import Axios from 'axios'


export async function fetchNews() {
  const url = `/api/news`
  const res = await Axios.get(url)
  return res.data
}


export function hasSource(data: any): data is { source: string } {
  if (data.source && typeof data.source === "string") return true
  return false
}