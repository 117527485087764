import { IRRemote } from "../IRRemote"
import StyledPaper from "../StyledPaper"

export function Remote() {
  return <div>
    <StyledPaper>
      <div className="flex justify-center">
        <div>
          <IRRemote />
        </div>
      </div>
    </StyledPaper>
  </div>
}