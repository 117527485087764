import { apiPost } from "./api"

export type RemoteData = {
  deviceKey: string
  device: string
  functionKey: string
  function: string
  hex: string
  decimal: string
  bits: string
}


/** Posts an IR code to server */
export async function postIR(codeDecimal: string | number, bits: string | number) {
  await apiPost("/api/protected/ir", { code: codeDecimal, bits: bits })
}