import { format, isMatch, parse } from "date-fns"
import produce from "immer"
import { useCallback, useMemo, useState } from "react"
import validator from "validator"
import { v4 as uuidv4 } from "uuid"

type AqLightScheduleInputProps = {
  value?: AqLights.ScheduleItem[]
  onChange: (value: AqLights.ScheduleItem[]) => void
}
const defaultInputValues = { time: "", percent: "", instant: false }
export function AqLightScheduleInput({ value, onChange }: AqLightScheduleInputProps) {
  const [inputValues, setInputValues] = useState(defaultInputValues)

  const handleDeleteClick = useCallback((i: number) => {
    if (!value) return
    if (!window.confirm("Are you sure you want to delete this item?")) return
    onChange(produce(value, draft => {
      draft.splice(i, 1)
    }))
  }, [onChange, value])

  const handleAddClick = useCallback(() => {
    if (!isMatch(inputValues.time, "HH:mm:ss")) {
      alert("Invalid time")
      return
    }
    if (!validator.isFloat(inputValues.percent)) {
      alert("Invalid percent")
      return
    }
    const percent = parseFloat(inputValues.percent)
    if (percent < 0 || percent > 100) {
      alert("Invalid percent range")
      return
    }
    onChange(produce(value || [], draft => {
      const formattedTime = format(parse(inputValues.time, "HH:mm:ss", new Date()), "HH:mm:ss")
      const existing = draft.findIndex(item => item.time === formattedTime)
      const newItem = {
        time: formattedTime,
        percentage: parseFloat(inputValues.percent),
        instant: inputValues.instant,
      }
      if (existing >= 0) draft[existing] = newItem
      else draft.push(newItem)
      draft.sort((a, b) => a.time === b.time ? 0 : a.time > b.time ? 1 : -1)
    }))
    setInputValues(defaultInputValues)
  }, [inputValues, onChange, value])

  const handleRowClick = useCallback((item: AqLights.ScheduleItem, i: number) => {
    setInputValues({
      time: item.time,
      percent: item.percentage.toString(),
      instant: item.instant,
    })
  }, [])
  const key = uuidv4()

  const isUpdate = useMemo(() => {
    if (!value) return false
    if (!inputValues.time) return false
    try {
      const formattedTime = format(parse(inputValues.time, "HH:mm:ss", new Date()), "HH:mm:ss")
      return !!value.find(item => item.time === formattedTime)
    } catch {
      // Catch invalid time values
      return false
    }
  }, [inputValues.time, value])

  return <div className="border rounded p-2">
    <div className="grid gap-y-1">
      <div className="grid gap-y-1">
        {value?.map((item, i) =>
          <div key={i + " " + item.time} className="flex">
            <button onClick={() => handleRowClick(item, i)}>
              {item.time}
            </button>
            <span className="ml-auto">
              {item.percentage}
            </span>
            {item.instant
              ? <span className="ml-1 text-slate-500 bi bi-arrow-90deg-up -scale-x-100" title={"Instant"} />
              : <span className="ml-1 text-slate-500 bi bi-arrow-up-right" title={"Gradual"} />
            }
            <button className="border ml-2 px-1"
              onClick={() => handleDeleteClick(i)}
            ><i className="bi bi-x" /></button>
          </div>
        )}
      </div>
      <input className="text-field w-full"
        placeholder="HH:MM:SS"
        value={inputValues.time}
        onChange={e => setInputValues(produce(draft => { draft.time = e.target.value }))}
      />
      <input className="text-field w-full"
        placeholder="Percent"
        value={inputValues.percent}
        onChange={e => setInputValues(produce(draft => { draft.percent = e.target.value }))}
      />
      <div>
        <input
          type="checkbox"
          checked={inputValues.instant}
          id={`instant-check-${key}`}
          onChange={(e) => setInputValues({ ...inputValues, instant: e.target.checked })} />
        <label
          className="ml-1"
          htmlFor={`instant-check-${key}`}>Instant</label>
      </div>
      <button
        onClick={handleAddClick}
        className="btn-primary"
      >{isUpdate ? "Update" : "Add"}</button>
    </div>
  </div>
}