import produce from "immer"
import { useCallback, useEffect, useState } from "react"
import { mutate } from "swr"
import validator from "validator"
import { apiPost, useApiGet } from "../lib/api"


type IncubatorKey = keyof Controller.Config<"chickenIncubator">

interface ConfigDef<K extends string> {
  key: K
  label: string
  type: "number" | "string" | "schedule"
}
const incubatorFields: ConfigDef<IncubatorKey>[] = [
  { key: "label", label: "Label", type: "string" },
  { key: "setTempC", label: "Set Temp °C", type: "number" },
  { key: "maxHeat", label: "Max Heat", type: "number" },
]

type IncubatorConfigProps = {
  name: string
}
export function IncubatorConfig({ name }: IncubatorConfigProps) {
  const { data, error } = useApiGet<{ config: Controller.Config<"chickenIncubator"> }>(name ? `/api/protected/controller/config/chickenIncubator/${name}` : null)
  const [input, setInput] = useState<Partial<Record<IncubatorKey, string>>>({})
  // Used to show changes
  const [originalInput, setOriginalInput] = useState<Partial<Record<IncubatorKey, string>>>({})

  useEffect(() => {
    const keys = incubatorFields.map(field => field.key)
    const newInput = keys.reduce((acc, key) => {
      acc[key] = data?.config[key]?.toString() || ""
      return acc
    }, {} as Partial<Record<IncubatorKey, string>>)
    setInput(newInput)
    setOriginalInput(newInput)
  }, [data?.config])

  const handleInputChange = useCallback((key: IncubatorKey, value: string) => {
    setInput(produce(draft => {
      draft[key] = value
    }))
  }, [])

  const handleSetClick = useCallback(() => {
    const post = async () => {
      if (typeof input.label !== "string") throw new Error("Invalid label")
      if (!input.maxHeat || !validator.isInt(input.maxHeat)) throw new Error("Invalid max heat")
      if (!input.setTempC || !validator.isFloat(input.setTempC || "")) throw new Error("Invalid set temp C")
      const newConfig: Controller.Config<"chickenIncubator"> = {
        label: input.label,
        maxHeat: parseInt(input.maxHeat),
        setTempC: parseFloat(input.setTempC)
      }
      await apiPost(`/api/protected/controller/config/chickenIncubator/${name}`, { config: newConfig })
      mutate("/api/protected/controllers")
      mutate(`/api/protected/controller/config/chickenIncubator/${name}`)
    }
    post()
  }, [input.label, input.maxHeat, input.setTempC, name])

  return <div>
    {error && <div>{error}</div>}
    <div className="grid gap-2">
      {incubatorFields.map(field =>
        <div key={field.key}>
          <div className="w-full">
            <label className="text-sm text-zinc-500">
              {field.label}
            </label>
          </div>
          <input
            onChange={e => handleInputChange(field.key, e.currentTarget.value)}
            className={`text-field w-full ${input[field.key] !== originalInput[field.key] ? "bg-yellow-200" : ""}`}
            value={input[field.key] || ""} />
        </div>
      )}
      <button className="btn-primary w-full"
        onClick={handleSetClick}
      >Set</button>
    </div>
  </div>
}