import { createAsyncThunk } from "@reduxjs/toolkit"
import Axios from "axios"
import { AppDispatch, RootState } from "../store"


export const login = createAsyncThunk<
  boolean, { username: string, password: string }, {
    dispatch: AppDispatch, state: RootState
  }
>("login", async (arg, { dispatch }) => {
  const { username, password } = arg
  const response = await Axios.post("/login", { username, password })
  if (response.data?.status !== "success") throw new Error("Login error")
  const res = await dispatch(verifyLogin())
  return !!res.payload
})

interface User {
  username: string
  nameFirst: string
  nameLast: string
  password: string
}
export const verifyLogin = createAsyncThunk<
  User, void, {
    dispatch: AppDispatch, state: RootState
  }
>("login/verify", async (_, { getState, dispatch }) => {
  const verifyResponse = await Axios.post("/api/protected/verify")
  if (!verifyResponse.data.user) throw new Error("Invalid user")
  return verifyResponse.data.user
})

export const logout = createAsyncThunk<
  void, void, {
    dispatch: AppDispatch, state: RootState
  }
>("logout", async (_, { dispatch }) => {
  const response = await Axios.post("/login/logout")
  if (response.data?.status !== "success") throw new Error("Logout error")
})