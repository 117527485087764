import { weatherIconFromCode } from "../lib/weather"
import biSvg from "bootstrap-icons/bootstrap-icons.svg"


export function WeatherChartTickIcon(props: Record<string, any>) {
  const weather = props.data[props.payload.index].weather[0]
  const icon = weatherIconFromCode(weather.icon || "").icon || ""
  return <svg
    x={props.x - 8}
    y={props.y - 16}
    width={16}
    height={16}
    role="img"
    fill="black"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={"bi"}>
    <use xlinkHref={`${biSvg}#${icon}`} />
  </svg>
}