import { useEffect, useRef } from "react"

import { postIR, RemoteData } from "../lib/irRemote"

import "./IRRemoteSVG.scss"

type IRRemoteSVGProps = {
  component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  deviceKey: string
  data: RemoteData[] | undefined
  label: string
}

export function IRRemoteSVG({ component, deviceKey, data, label }: IRRemoteSVGProps) {
  const svgRef = useRef<SVGSVGElement>(null)
  const Component = component

  // Register svg buttons
  useEffect(() => {
    const svg = svgRef.current
    if (!svg) return
    const buttons = svg.querySelectorAll("g[id^='button-'")
    const listeners: (() => void)[] = []
    buttons.forEach((button, i) => {
      // Set styles
      button.classList.add("IRRemoteButton")
      const listener = () => {
        if (!data) return
        const id = button.id
        if (!id) {
          console.log("Button id not found.")
          return
        }
        const buttonKey = id.replace(/^button-/, "")
        const remoteData = data.find(row =>
          row.deviceKey === deviceKey && row.functionKey === buttonKey
        )
        if (remoteData) {
          postIR(remoteData.decimal, remoteData.bits)
        }
      }
      listeners.push(listener)

      button.addEventListener("click", listener)
    })
    return () => {
      buttons.forEach((button, i) => {
        button.removeEventListener("click", listeners[i])
      })
    }
  }, [data, deviceKey])
  return <div className="border shadow rounded-2xl overflow-hidden">
    <h2 className="text-center text-2xl bg-slate-700 text-white p-2">{label}</h2>
    <Component style={{ width: "100%", height: "auto" }} ref={svgRef} />
  </div>

}