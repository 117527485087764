import axios from "axios"
import produce from "immer"
import { useCallback, useState } from "react"

export function ChangePasswordForm() {
  type InputKeys = "oldPassword" | "newPassword" | "confirmPassword"
  type InputData = { [K in InputKeys]?: string | undefined }
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [input, setInput] = useState<InputData>({})
  const [submitError, setSubmitError] = useState("")
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const handleUpdatePasswordClick = useCallback(() => {
    const submitPasswordUpdate = async () => {
      setIsSubmitting(true)
      setSubmitSuccess(false)
      try {
        const { oldPassword, newPassword, confirmPassword } = input
        if (
          (typeof oldPassword !== "string" || oldPassword === "")
          || (typeof newPassword !== "string" || newPassword === "")
          || (typeof confirmPassword !== "string" || confirmPassword === "")
        ) {
          setSubmitError("Form incomplete.")
          return
        }
        if (newPassword.length < 6) {
          setSubmitError("New password must be at least 6 characters long")
          return
        }
        if (newPassword !== confirmPassword) {
          setSubmitError("Passwords do not match.")
          return
        }
        try {
          const res = await axios.post("/api/protected/updatePassword", { oldPassword, newPassword, confirmPassword })
          if (res.data?.status === "badoldpass") {
            setSubmitError("Old password incorrect.")
            return
          }
          if (res.data?.status !== "success") {
            setSubmitError("Error submitting password")
            return
          }
          setSubmitError("")
          setSubmitSuccess(true)
          setInput({})
        } catch (err) {
          setSubmitError("Server error.")
        }
      } finally {
        setIsSubmitting(false)
      }
    }
    submitPasswordUpdate()
  }, [input])
  return <div className="grid gap-2">
    <h2 className="text-xl">
      Change Password
    </h2>
    <form className="grid gap-2" onSubmit={(e) => e.preventDefault()}>
      <div className="grid">
        <div>
          Old Password
        </div>
        <input
          type="password"
          disabled={isSubmitting}
          className="text-field" value={input.oldPassword || ""}
          onChange={e => setInput(produce(draft => { draft.oldPassword = e.target.value }))}
        />
      </div>
      <div className="grid">
        <div>
          New Password
        </div>
        <input
          type="password"
          disabled={isSubmitting}
          className="text-field" value={input.newPassword || ""}
          onChange={e => setInput(produce(draft => { draft.newPassword = e.target.value }))}
        />
      </div>
      <div className="grid">
        <div>
          Confirm New Password
        </div>
        <input
          type="password"
          disabled={isSubmitting}
          className="text-field" value={input.confirmPassword || ""}
          onChange={e => setInput(produce(draft => { draft.confirmPassword = e.target.value }))}
        />
      </div>
      {submitError && <div className="border px-3 py-2 border-red-800 bg-red-200 text-red-900 text-center">
        Error: {submitError}
      </div>}
      {submitSuccess && <div className="border px-3 py-2 border-green-800 bg-green-200 text-green-900 text-center">
        Password changed!
      </div>}
      <div className="grid">
        <button
          type="submit"
          disabled={isSubmitting}
          className="btn-primary"
          onClick={handleUpdatePasswordClick}
        >Update Password</button>
      </div>
    </form>
  </div>
}