import { useApiGet } from "../lib/api"
import { getControllerTypes } from "../lib/controllers"
import { IncubatorConfig } from "./IncubatorConfig"


export function Controllers() {
  const { data, error } = useApiGet<{ controllers: Controller.AllControllerNames }>("/api/protected/controllers")
  // console.log(controllers)
  return <div>
    {error && <div>{error}</div>}
    {getControllerTypes().map(type =>
      <div key={type}>
        <h2 className="text-xl">{type}</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          {data?.controllers[type].map(name =>
            <div key={name} className="bg-white rounded p-2 shadow">
              {name}
              {type === "chickenIncubator" && <IncubatorConfig name={name} />}
            </div>
          )}
        </div>
      </div>
    )}
  </div>
}