import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import useSWR from 'swr'

async function fetchQuote(dayOffset = 0) {
  const url = `/api/quote?dayOffset=${dayOffset}`
  const res = await Axios.get(url)
  return res.data
}

function useQuote(offset: number) {
  const [prevQuote, setPrevQuote] = useState<any>(null)
  const { data, error } = useSWR([offset, "quote"], fetchQuote)
  useEffect(() => {
    if (data) setPrevQuote(data)
  }, [data])
  const isLoading = !data && !error
  return { isLoading, data: data || prevQuote }
}

export function QuoteBox() {
  const [quoteDayOffset, setQuoteDayOffset] = useState(0)
  const { data: quoteData, isLoading } = useQuote(quoteDayOffset)
  return <div>
    <div className="flex justify-center w-full gap-5">
      <button
        className="btn-text"
        onClick={e => setQuoteDayOffset(offset => offset - 1)}
        disabled={false}>
        <span className="bi bi-chevron-left" /> Previous</button>
      <button
        className="btn-text"
        onClick={e => setQuoteDayOffset(0)}
        disabled={quoteDayOffset >= 0}>
        Today</button>
      <button
        className="btn-text"
        onClick={e => setQuoteDayOffset(offset => offset + 1)}
        disabled={quoteDayOffset >= 0}>
        Next <span className="bi bi-chevron-right" /></button>
    </div>
    <div className="p-3 md:p-5">
      <div className={`text-3xl text-center ${isLoading ? "text-gray-500" : "text-black"}`}>
        {quoteData?.quote}
      </div>
      <div className="text-center text-slate-500">
        {quoteData?.source
          ? <>
            &mdash;<a
              className="a-primary"
              href={`https://www.google.com/search?q=${encodeURIComponent(quoteData.source)}`}
              rel="noreferrer noopener"
              target="_blank"
            >
              {quoteData.source}
            </a>
          </>
          : "Anonymous"
        }
      </div>
    </div>
  </div>
}
