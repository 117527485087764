import React, { useCallback } from "react"
import { NavLink } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../store/store"
import { setFormOpen } from "../store/reducers/login"
import { logout } from "../store/actions/login"
import { PufferFish } from "./PufferFish"

export default function PageHeader() {
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(state => state.login.isLoggedIn)
  const nameFirst = useAppSelector(state => state.login.nameFirst)
  const nameLast = useAppSelector(state => state.login.nameLast)

  const navLinkClassName = useCallback(({ isActive }: { isActive: boolean }) =>
    `px-5 py-3 ${isActive ? "text-white bg-sky-500 hover:bg-sky-600" : "text-gray-500 bg-white hover:bg-sky-100"}`
    , [])
  return <div className="bg-neutral-50 shadow mb-2">
    <div className="container mx-auto flex gap-2 flex-wrap">
      <div className="p-3 sm:order-1 ">
        <PufferFish />
      </div>
      <div className="grow sm:order-3"></div>
      <div className="flex gap-2 items-center p-1 sm:order-4">
        <div>{nameFirst} {nameLast[0]}</div>
        <div>
          {isLoggedIn
            ? <button className="btn-primary" onClick={() => dispatch(logout())}>Logout</button>
            : <button className="btn-primary" onClick={() => dispatch(setFormOpen(true))}>Login</button>
          }
        </div>
      </div>
      <div className="flex divide-y divide-x-0 sm:divide-y-0 sm:divide-x bg-white flex-wrap flex-col sm:flex-row w-full sm:w-auto sm:order-2">
        <NavLink className={navLinkClassName} to="/" >Dashboard</NavLink>
        {isLoggedIn && <NavLink className={navLinkClassName} to="/remote">Remote</NavLink>}
        {isLoggedIn && <NavLink className={navLinkClassName} to="/mcu">MCU</NavLink>}
        {isLoggedIn && <NavLink className={navLinkClassName} to="/account">Account</NavLink>}
      </div>
    </div>
  </div>
}