import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'
import { rootReducer } from './rootReducer'

export const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export type AppThunk<ReturnType = void, A = undefined> = ThunkAction<ReturnType, RootState, A, AnyAction>

/** Creates an app thunk while inferring the return type properly */
export const appThunk = <T>(thunk: AppThunk<T>) => thunk