import { useEffect, useState } from "react"
import { motion } from "framer-motion"
import { useLocation } from "react-router-dom"
import puffer from "../assets/puffer.png"

export function PufferFish() {
  const [prevPathname, setPrevPathname] = useState("")
  const [rotation, setRotation] = useState(0)
  const location = useLocation()
  useEffect(() => {
    if (prevPathname !== location.pathname) {
      setPrevPathname(location.pathname)
      setRotation(r => r + 1)
    }
  }, [location.pathname, prevPathname])

  return <motion.div
    key={rotation}
    transition={{
      duration: 0.75
    }}
    whileHover={{
      scale: [1, 1.5, 1],
      transition: {
        repeat: Infinity
      }
    }}
    initial={{
      scale: 1,
      rotate: 0,
    }}
    animate={{
      scale: [1, 1.5, 1],
      rotate: -360,
    }}
  >
    <img
      className=""
      style={{ height: 25 }}
      src={puffer} alt="" />
  </motion.div>
}