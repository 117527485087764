import { ReactComponent as TVSvg } from "../assets/irRemote/tv.svg"
import { ReactComponent as SoundSvg } from "../assets/irRemote/sound.svg"
import { IRRemoteSVG } from "./IRRemoteSVG"
import { RemoteData } from "../lib/irRemote"
import { useApiGet } from "../lib/api"

export function IRRemote() {
  const { data, error } = useApiGet<RemoteData[]>("/api/protected/ir")
  return <div>
    {error && <div>Error: {error.toString()}</div>}
    <div className="grid lg:grid-cols-2 gap-10 lg:gap-36">
      <div className="lg:max-w-[300px] lg:order-2">
        <IRRemoteSVG label="Television" deviceKey="tv" component={TVSvg} data={data} />
      </div>
      <div className="lg:max-w-[300px] lg:order-1">
        <IRRemoteSVG label="Sound Bar" deviceKey="sound" component={SoundSvg} data={data} />
      </div>
    </div>
  </div>
}