import React from "react"
import { Navigate } from "react-router-dom"
import { useAppSelector } from "../store/store"


type ProtectedProps = { children?: React.ReactNode }
export function Protected({ children }: ProtectedProps) {
  const isLoggedIn = useAppSelector(state => state.login.isLoggedIn)
  const triedAutoLogin = useAppSelector(state => state.login.triedAutoLogin)
  if (!isLoggedIn) {
    // If they haven't tried autologin yet, don't navigate yet
    if (!triedAutoLogin) return null
    return <Navigate to="/" />
  }
  return <>{children}</>
}