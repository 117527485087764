import { Route, Routes } from "react-router-dom"
import { Protected } from "./Protected"
import { Account } from "./routes/Account"
import { Home } from "./routes/Home"
import { MCU } from "./routes/MCU"
import { Remote } from "./routes/Remote"

export function MainRoutes() {
  return <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/remote" element={<Protected><Remote /></Protected>} />
    <Route path="/mcu" element={<Protected><MCU /></Protected>} />
    <Route path="/account" element={<Protected><Account /></Protected>} />
  </Routes>
}